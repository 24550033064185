import './custom';
import '../css/app.css';
import { createApp, h } from 'vue';
import { createInertiaApp, Link, Head } from '@inertiajs/vue3';
import mitt from 'mitt';

window.mitt = window.mitt || new mitt();

const app = createApp({});
import CookieConsentVue from '@/Plugins/CookieConsentVue.js';
import { Model } from 'vue-api-query';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VueScrollTo from 'vue-scrollto';
import Counter from '@/Blocks/Counter.vue';

// Tippy
import VueTippy, { setDefaultProps } from 'vue-tippy';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/shift-away.css';

// import * as Sentry from '@sentry/browser';
// import * as Sentry from '@sentry/vue';

// document.addEventListener('inertia:finish', (event) => {
//     // Track GoSquared
//     // if(typeof _gs !== 'undefined'){
//     //     _gs('track',event.detail.visit.url.pathname)
//     // }

//     // Fallback js error
//     var url = new URL(window.location.href);

//     if (url.pathname != event.detail.visit.url.pathname && event.detail.visit.method == 'get') {
//         window.location.href = event.detail.visit.url.href;
//     }
// });

// Sentry
// Sentry.init({
//     app,
//     dsn: 'https://50dc180035b6424d8beaa3c01abf1d0c@o1143293.ingest.sentry.io/4504281357615104',
//     integrations: [
//         new Sentry.BrowserTracing({
//             release: 'javascript-vue@1.01',
//             // routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//             // tracePropagationTargets: ['localhost', 'doorax.nl', 'www.doorax.nl'],
//             tracePropagationTargets: ['localhost', /^https:\/\/doorax\.nl/],
//             // routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//             traceOrigins: ['*'],
//         }),
//         // new Sentry.Replay(),
//     ],
//     environment: env,
//     // trackComponents: true,
//     logErrors: true,
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 0.1,

//     // in development and sample at a lower rate in production
//     // replaysSessionSampleRate: 0.1,
//     // If the entire session is not sampled, use the below sample rate to sample
//     // sessions when an error occurs.
//     // replaysOnErrorSampleRate: env == 'production' ? 1.0 : 0.0,
// });

setDefaultProps({
    animation: 'shift-away',
});

// inject global axios instance as http client to Model
Model.$http = axios;
// window.Sentry = Sentry;
// axios.interceptors.request.use(function (config) {
//         console.log(config);
//     // Do something before request is sent
//     return config;
//   }, function (error) {
//     // Do something with request error
//     return Promise.reject(error);
//   });

// axios.interceptors.response.use((resp) => {
//     let v = resp.headers['vers'] || 'default';
//     console.log('gg');
//     console.log(v);

//     if (v !== localStorage.getItem('vers') && resp.config.method == 'get') {
//         localStorage.setItem('vers', v);
//         window.location.reload(); // For new version, simply reload on any get
//     }

//     return Promise.resolve(resp);
// });

import VuePicture from '@/Components/VuePicture.vue';
import Card from '@/Layouts/Card.vue';
import Layout from '@/Layouts/AppLayout.vue';
import InfoModal from '@/Components/InfoModal.vue';
import Date from '@/Blocks/Date.vue';
import ModalComponent from '@/Components/Modal.vue';
import UploadFile from '@/Components/Configuration/UploadFile.vue';

import CylinderSize from '@/Components/Configuration/CylinderSize.vue';
import Buttons from '@/Components/Configuration/Buttons.vue';
import KeyQty from '@/Components/Configuration/KeyQty.vue';
import Checkbox from '@/Components/Checkbox.vue';
import WizardIntro from '@/Blocks/Aside/Wizard.vue';

import Validation from '@/Components/Validation.vue';
import ReviewStars from '@/Components/ReviewStars.vue';



// Axios
import axios from 'axios';
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
import RollbarPlugin from '@/Plugins/rollbar';
// import RollbarPlugin from '@/Plugins/rollbarLocal';

// Fonts
import './fonts';
// HeadlessUI
import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Popover,
    PopoverButton,
    PopoverPanel,
    RadioGroup,
    RadioGroupDescription,
    RadioGroupLabel,
    RadioGroupOption,
} from '@headlessui/vue';

import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';

createInertiaApp({
    progress: {
        color: '#369fff',
    },
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) });

        // if (env != 'local'){
        //     Sentry.init({
        //         app,
        //         dsn: 'https://50dc180035b6424d8beaa3c01abf1d0c@o1143293.ingest.sentry.io/4504281357615104',
        //         environment: env,
        //     });
        // }

        app.use(plugin)
            .use(CookieConsentVue)
            .use(VueTippy)
            .use(RollbarPlugin)
            .use(VueScrollTo, {
                container: 'body',
                easing: 'ease-in',
                lazy: true,
                force: true,
                cancelable: true,
                onStart: function (element) {
                    // scrolling started
                },
                onDone: function (element) {
                    // scrolling is done
                },
                onCancel: function () {
                    // scrolling has been interrupted
                },
                x: false,
                y: true,
            })
            .component('InertiaLink', Link)
            .component('InertiaHead', Head)
            .component('Fa', FontAwesomeIcon)
            .component('Card', Card)
            .component('Date', Date)
            .component('Layout', Layout)
            .component('VuePicture', VuePicture)
            .component('InfoModal', InfoModal)
            .component('Modal', ModalComponent)
            .component('ReviewStars', ReviewStars)
            .component('Counter', Counter)

            // Headless UI
            .component('Menu', Menu)
            .component('MenuButton', MenuButton)
            .component('MenuItem', MenuItem)
            .component('MenuItems', MenuItems)
            .component('Popover', Popover)
            .component('PopoverButton', PopoverButton)
            .component('PopoverPanel', PopoverPanel)

            .component('RadioGroup', RadioGroup)
            .component('RadioGroupDescription', RadioGroupDescription)
            .component('RadioGroupLabel', RadioGroupLabel)
            .component('RadioGroupOption', RadioGroupOption)

            .component('Listbox', Listbox)
            .component('ListboxLabel', ListboxLabel)
            .component('ListboxButton', ListboxButton)
            .component('ListboxOption', ListboxOption)
            .component('ListboxOptions', ListboxOptions)

            .component('Dialog', Dialog)
            .component('DialogOverlay', DialogOverlay)
            .component('DialogTitle', DialogTitle)
            .component('TransitionChild', TransitionChild)
            .component('TransitionRoot', TransitionRoot)

            // Wizard
            .component('CylinderSize', CylinderSize)
            // .component('CylinderQty', CylinderQty)
            .component('Buttons', Buttons)
            .component('KeyQty', KeyQty)
            .component('Validation', Validation)
            .component('Checkbox', Checkbox)
            .component('WizardIntro', WizardIntro)

            // Need to override
            // .component('Nl2br', Nl2br)

            .mixin({
                methods: {
                    url(value, param) {
                        if (param) {
                            return `${base_url}/${value}/${param}`;
                        } else {
                            return `${base_url}/${value}`;
                        }
                    },
                    asset(value, param) {
                        if (param) {
                            return `${file_asset_url}/${value}/${param}`;
                        } else {
                            return `${file_asset_url}/${value}`;
                        }
                    },

                    price(value) {
                        if (value == 0) return '0,00';
                        if (!value) return '';
                        return currencyFormat(Number(value));
                    },

                    priceEuro(value) {
                        if (value == 0) return '0,00';
                        if (!value) return '';
                        return '€ ' + currencyFormat(Number(value));
                    },
                },
            })

            // Product
            .component('UploadFile', UploadFile)
            .mount(el);

        return app;
    },
});

function currencyFormat(num) {
    return num
        .toFixed(2)
        .replace('.', ',')
        .replace(',00', ',-')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}
