import Rollbar from 'rollbar';
import config from './rollbar.config';

const rollbar = new Rollbar(config);

export default {
    install(app) {
        app.config.errorHandler = (error, vm, info) => {
            rollbar.configure({
                checkIgnore: function (isUncaught, args, payload) {
                    let message = payload.body.trace.exception.message;

                    return message.includes('Object.prototype.hasOwnProperty');
                    
                    // ignore all uncaught errors and all 'debug' items
                    // return isUncaught === true || payload.level === 'debug';
                },
            });
            rollbar.error(error, { vueComponent: vm, info });
            
            if (window.env == 'local') {
                console.error(error);
            }
        };
        
        app.provide('rollbar', rollbar);
    },
};
