<template>
    <div class="text-center">
        <div class="flex flex-wrap">
            <div
                class="my-6 drag-area p-2 rounded-lg bg-gray-100 cursor-pointer hover:bg-gray-200 w-full md:w-1/2 relative"
            >
                <div class="border border-dashed border-gray-400 rounded-lg p-6">
                    <div class="pb-2">
                        <fa :icon="['fas', 'cloud-upload']" class="h-8 text-gray-300" />
                    </div>
                    <header class="text-lg my-4">Sleep bestand om te uploaden</header>
                    <span class="block mb-4 divider">Of</span>
                    <button class="button dgreen mx-auto mb-2">Selecteer bestand</button>
                    <div class="image mx-auto flex justify-center"></div>

                    <input type="file" class="file-input" @change="fileUpload()" @drop="fileUpload()" ref="files" />

                    <div class="flex items-center mt-4" v-if="loading">
                        <fa :icon="['fad', 'spinner-third']" class="text-dgreen opacity-80 mr-2 fa-spin" />
                        <span>Uploaden...</span>
                    </div>

                    <div class="flex items-center mt-4" v-if="data.result.succeed || this.file">
                        <fa :icon="['fas', 'check-circle']" class="text-green-600 mr-2" />
                        <span> Bestand succesvol geüpload </span>
                    </div>

                    <div class="text-center mt-4" v-if="data.result.error">
                        <fa  :icon="['fas', 'exclamation-triangle']" class="text-red-600 mr-2" />
                        <span class="block font-bold">Upload mislukt.</span><span class="block">Geldige bestandsformaten: .jpg, .png, .pdf. (Maximaal 5mb)</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, ref } from 'vue';

export default {
    props: ['file'],

    data() {
        return {
            uploaded: [],
        };
    },

    mounted() {
        this.$nextTick(function () {
            const dropArea = document.querySelector('.drag-area');
            if (document.body.contains(dropArea)) {
                const dragText = dropArea.querySelector('.drag-area header');
                const button = dropArea.querySelector('.drag-area button');
                const input = dropArea.querySelector('.drag-area input');
                const image = dropArea.querySelector('.drag-area .image');
                const divider = dropArea.querySelector('.drag-area .divider');
                let file;

                button.onclick = () => {
                    input.click();
                };
                input.addEventListener('change', function () {
                    file = this.files[0];
                    dragText.textContent = file.name;
                    dropArea.classList.add('active');
                    button.textContent = 'Ander bestand selecteren';
                    divider.classList.add('hidden');
                    showFile();
                });

                dropArea.addEventListener('dragover', (event) => {
                    event.preventDefault();
                    dropArea.classList.add('active');
                    dragText.textContent = 'Laat los om te uploaden';
                });

                dropArea.addEventListener('dragleave', () => {
                    dropArea.classList.remove('active');
                    dragText.textContent = 'Sleep bestand om te uploaden';
                });

                dropArea.addEventListener('drop', (event) => {
                    event.preventDefault();
                    file = event.dataTransfer.files[0];
                    input.files = event.dataTransfer.files;
                    // input.value = file;``
                    dragText.textContent = file.name;
                    dropArea.classList.add('dropped');
                    button.textContent = 'Ander bestand selecteren';
                    divider.classList.add('hidden');
                    showFile();
                });
                function showFile() {
                    let fileType = file.type;
                    let validExtensions = [
                        'image/jpeg',
                        'image/jpg',
                        'image/png',
                        'application/pdf',
                        'application/pdf',
                    ];
                    if (validExtensions.includes(fileType)) {
                        let fileReader = new FileReader();
                        fileReader.onload = () => {
                            let fileURL = fileReader.result;
                            let imgTag = `<img src="${fileURL}" alt="image" class=" h-32">`;

                            image.innerHTML = imgTag;
                        };
                        fileReader.readAsDataURL(file);
                    }
                }
            }
        });
    },

    setup(props, context) {
        const files = ref();
        const loading = ref(false);

        const data = reactive({
            file: false,
            uploaded: false,
            result: {
                succeed: false,
                error: false,
            },
        });

        function fileUpload() {
            setTimeout(() => {
                let uploadedFiles = this.files.files;

                // this.$emit('update:file', uploadedFiles[0]);
                this.data.uploaded = true;
                this.data.file = uploadedFiles[0];

                this.startUpload();
            }, 100);
        }

        function startUpload() {
            let self = this;
            self.loading = true;

            const formData = new FormData();
            formData.append('file', this.data.file);

            axios
                .post('/winkelwagen/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then((result) => {
                    self.data.result = result.data;
                    context.emit('update:file', self.data.result.file);
                })
                .catch((errors) => {
                })
                .finally(() => {
                    self.loading = false;
                });
        }

        return { data, fileUpload, startUpload, files, loading };
    },
};
</script>

<style>
.file-input {
    height: 100%;
    left: 0;
    opacity: 0;
    outline: none;
    position: absolute;
    top: 0;
    width: 100%;
}

.drag-area.active {
    border: 1px solid rgba(5, 150, 105, var(--tw-border-opacity));
    background: #fff;
    transition: background-color 0.5s ease;
}
</style>
