// rollbar.config.js
export default {
    // accessToken: '59873a82c27b4f5bb7aabe4ce99ade02',
    accessToken: 'ebe34e916fe948e89f2ebb65e58c488b',
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
        environment: window.env,
        client: {
            javascript: {
                source_map_enabled: true, // false by default
                code_version: '1.0.0',
                guess_uncaught_frames: true,
            },
        },
    },
};
